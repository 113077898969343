<template>
  <page-container title="基础功能管理" breadcrumb="">
    <div class="left-page" >
      <div class="box-container-inner" style="height: 100%; overflow: auto;">
        <div style="display: flex">
          <h2>监控点</h2>
          <a-radio-group v-model="type" default-value="four" button-style="solid" style="margin-left: auto">
            <a-radio-button value="four">
              四宫格
            </a-radio-button>
            <a-radio-button value="nine">
              九宫格
            </a-radio-button>
          </a-radio-group>
        </div>
        <div style="display: flex;margin-top: 5px">
          <a-input v-model="userdepname"></a-input>
          <a-button type="primary" v-if="show" @click="search">搜索</a-button>
          <a-button type="primary" v-if="!show" @click="returnPage">返回</a-button>
        </div>
        <a-tree
            v-if="show"
            :selected-keys.sync="selectedKeys"
            :replace-fields="{children:'children', title:'userdepname', key:'userdepid'}"
            :tree-data="userDeptTree"
            :load-data="onLoadData"
            @select="selectDept"
        >
        </a-tree>
        <a-table :columns="tableColumns" :data-source="tableData" :customRow="rowClick" v-if="!show" row-key="userdepid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;">
        </a-table>
      </div>
    </div>
    <div class="right-page">
      <div class="video" v-if="type=='four'">
        <div v-for="(item, index) in lists" :key="index" style="border:1px solid white;width: 50%;height: 50%">
          <div>
            <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:99999;width: 400px;color: white">{{item.name}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl"></h3>
          </div>
          <video
              :id="'video' + item.id"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px">
          </video>
        </div>
      </div>
      <div class="video" v-if="type=='nine'">
        <div v-for="(item, index) in lists2" :key="index" style="border:1px solid white;width: 33.33%;height: 33.33%">
          <div>
            <h3 style="margin-top: 5px;margin-left: 10px;position:fixed; z-Index:99999;width: 400px;color: white">{{item.name}}<img src="~@/assets/image/yclj.png" style="position:relative; z-Index:99999;width: 30px;" @click="remoteControl"></h3>
          </div>
          <video
              :id="'video' + item.id"
              controls = "true"
              width="100%"
              height="100%"
              display="flex"
              style="margin-left: 0px">
          </video>
        </div>
      </div>
    </div>
  </page-container>
</template>
<script>
import flvjs from "flv.js/dist/flv.js"
import {getChildTreeListByParentId, getUserdepListByCondition} from "A/jcgn";
import {getDictByDicType} from "A/xtpz";
import {mapState} from "vuex";
export default {
  name: "playback",
  data () {
    return {
      userDeptTree: [],
      selectedKeys: [],
      show:true,
      type:'four',
      i:0,
      userdepname:'',
      tableData: [],
      tableLoading: false,
      tableColumns: [
        {
          title: '监控点名称',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
      ],
      flvPlayer:null,
      flvPlayers:[],
      videoElement:'',
      lists: [
        // {
        //   src: "http://video-mainserver.u-lake.com:11583/live/001.flv",
        //   name:"南京保利中心监控1-视频监控",
        //   id: 0,
        // },
      ],
      flvPlayer2:null,
      flvPlayers2:[],
      videoElement2:'',
      lists2:[],
    };
  },
  destroyed() {
    this.destroyAllFlv()
  },
  watch:{
    'type':function (){
      this.destroyAllFlv();
    },
  },
  mounted() {
    let that = this;
    setTimeout(function () {
      that.initPlayer();
    }, 1000);
  },
  created() {
    this.init()
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  methods: {
    init(){
      this.getDeptTypes();
      this.getDeptTree();
    },
    onLoadData(node) {
      return new Promise(resolve => {
        if (node.dataRef.children) {
          resolve();
          return;
        }
        let params = {
          parentid: node.dataRef.userdepid
        };
        this.showLoading();
        getChildTreeListByParentId(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            node.dataRef.children = res.item.map(item => {
              item.isLeaf = item.childsize === 0;
              item.scopedSlots = {title: 'customTreeNode'}
              return item;
            });
            this.userDeptTree = [...this.userDeptTree];
            resolve();
          }
        }).catch(err => {
          this.hideLoading();
        })
      });
    },
    selectDept(item) {
      let items;
      if(item.length>0){
        getUserdepListByCondition({userdepid:item[0]}).then(res=>{
          if(res&&res.returncode=='0'){
            items=res.item[0]
            if(items.levelid==15){
              if(this.type=='four'){
                let children={
                  src: "http://video-mainserver.u-lake.com:11583/live/"+items.monitorid+".flv",
                  name:items.userdepname,
                  id: this.lists.length,
                }
                if(this.lists.length>3){
                  this.flv_destroy(this.i)
                  if(this.i>3){
                    this.i=0
                  }
                  children.id=this.i
                  this.lists.splice(this.i,1,children)
                  this.i=this.i+1
                }else{
                  this.lists.push(children)
                }
                let that = this;
                setTimeout(function () {
                  that.preview(children.id,children.src);
                }, 1000);
              }else if(this.type=='nine'){
                let children={
                  src: "http://video-mainserver.u-lake.com:11583/live/"+items.monitorid+".flv",
                  name:items.userdepname,
                  id: this.lists2.length,
                }
                if(this.lists2.length>8){
                  this.flv_destroy(this.i)
                  if(this.i>8){
                    this.i=0
                  }
                  children.id=this.i
                  this.lists2.splice(this.i,1,children)
                  this.i=this.i+1
                }else{
                  this.lists2.push(children)
                }
                let that = this;
                setTimeout(function () {
                  that.preview(children.id,children.src);
                }, 1000);
              }
            }
          }
        })
      }
    },
    getDeptTypes() {
      let params = {
        dictype: '10'
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          if(this.userInfo.usertype == '0') {
            this.deptTypes = res.item;
          }else {
            this.deptTypes = res.item.filter(item => item.dickey == this.userInfo.usertype)
          }
        }
      })
    },
    getDeptTree(parentid='') {
      let params = {
        parentid
      };
      this.showLoading();
      getChildTreeListByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          let array=res.item.filter(items=>{
            if(items.deptype==1){
              return items
            }
          });
          this.userDeptTree = array.map(item => {
            item.isLeaf = item.childsize === 0;
            item.scopedSlots = {title: 'customTreeNode'}
            return item;
          });
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    initPlayer(){
      this.lists.forEach((item, index) => {
        if (flvjs.isSupported()) {
          this.videoElement = document.getElementById('video'+item.id);
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: item.src,
            stashInitialSize: 128,// 减少首桢显示等待时长
          });
          this.flvPlayer.attachMediaElement(this.videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
        }
      })
    },
    preview(id,src) {
      if (flvjs.isSupported()) {
        if(this.type=='four'){
          this.videoElement = document.getElementById('video' + id);
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: src,
            stashInitialSize: 128,// 减少首桢显示等待时长
          });
          this.flvPlayers.push(this.flvPlayer)
          this.flvPlayer.attachMediaElement(this.videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play();
        }else if(this.type=='nine'){
          this.videoElement2 = document.getElementById('video' + id);
          this.flvPlayer2 = flvjs.createPlayer({
            type: 'flv',
            url: src,
            stashInitialSize: 128,// 减少首桢显示等待时长
          });
          this.flvPlayers2.push(this.flvPlayer2)
          this.flvPlayer2.attachMediaElement(this.videoElement2);
          this.flvPlayer2.load();
          this.flvPlayer2.play();
        }
      }
    },
    remoteControl(){
    },
    flv_destroy(id) {
      if(this.type=='four'){
        this.flvPlayers[id].pause()
        this.flvPlayers[id].unload()
        this.flvPlayers[id].detachMediaElement()
        this.flvPlayers[id].destroy()
        this.flvPlayers[id] = null
      }else if(this.type=='nine'){
        this.flvPlayers2[id].pause()
        this.flvPlayers2[id].unload()
        this.flvPlayers2[id].detachMediaElement()
        this.flvPlayers2[id].destroy()
        this.flvPlayers2[id] = null
      }
    },
    destroyAllFlv(){
      if(this.type=='nine') {
        for (let i = 0; i < this.flvPlayers.length; i++) {
          this.flvPlayers[i].pause()
          this.flvPlayers[i].unload()
          this.flvPlayers[i].detachMediaElement()
          this.flvPlayers[i].destroy()
          this.flvPlayers[i] = null
          this.lists=[]
          this.selectedKeys=[null]
        }
      }else if(this.type=='four'){
        for (let i = 0; i < this.flvPlayers2.length; i++) {
          this.flvPlayers2[i].pause()
          this.flvPlayers2[i].unload()
          this.flvPlayers2[i].detachMediaElement()
          this.flvPlayers2[i].destroy()
          this.flvPlayers2[i] = null
          this.lists2=[]
          this.selectedKeys=[null]
        }
      }
    },
    search() {
      this.tableLoading = true;
      let params = {
        userdepname:this.userdepname,
        levelid:15
      };
      getUserdepListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.show=false;
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    returnPage(){
      this.show=true
    },
    rowClick(record, index) {
      let that=this
      return {
        on: {
          dblclick: () => {
            if(that.type=='four'){
              let children={
                src: "http://video-mainserver.u-lake.com:11583/live/"+record.monitorid+".flv",
                name:record.userdepname,
                id: record.monitorid,
              }
              if(that.lists.length>3){
                that.flv_destroy(that.i)
                if(that.i>3){
                  that.i=0
                }
                children.id=that.i
                that.lists.splice(that.i,1,children)
                that.i=that.i+1
              }else{
                that.lists.push(children)
              }
              that.$nextTick(()=>{
                that.preview(children.id, children.src)
              })
            }else if(that.type=='nine'){
              let children={
                src: "http://video-mainserver.u-lake.com:11583/live/"+record.monitorid+".flv",
                name:record.userdepname,
                id: record.monitorid,
              }
              if(that.lists2.length>8){
                that.flv_destroy(that.i)
                if(that.i>8){
                  that.i=0
                }
                children.id=that.i
                that.lists2.splice(that.i,1,children)
                that.i=that.i+1
              }else{
                that.lists2.push(children)
              }
              that.$nextTick(()=>{
                that.preview(children.id, children.src)
              })
            }
          }
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.left-page {
  float: left;
  display: flex;
  height: 91%;
  width: 18%;
  margin-top: 10px;
  margin-left: 10px;
  padding: 0 20px;
  background-color: white;
  padding: 10px 14px;
  border-radius: 2px;
}
.right-page {
  float: right;
  display: flex;
  height: 91%;
  width: 80%;
  margin-top: 10px;
  margin-right: 10px;
  padding: 0 20px;
  background-color: black;
  padding: 10px 14px;
  border-radius: 2px;
}
.right-left-page{
  width: 100%;
  height: 100%;
  border:2px solid white
}
.right-right-page{
  width: 50%;
  height: 100%;
  border:2px solid white
}
.video{
  display:flex;
  flex-wrap:wrap;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
</style>